'use strict';

const Polyglot = require('node-polyglot');

module.exports = (locale, phrases) => {
    window.Bonfire = (function (bonfire) {
        bonfire.locale = locale;
        bonfire.polyglot = new Polyglot({
            locale,
            phrases,
        });

        // Moment i18n
        if (window.moment !== undefined) {
            window.moment.locale(locale);
        }

        return bonfire;
    })(window.Bonfire || {});
};
